<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <!--    form Dialog-->
    <v-dialog
      v-model="dialog"
      max-width="1200px"
      class="transparent"
    >
      <v-card
        flat
        class="mx-auto my-8 transparent"
      >
        <base-material-card
          color="black"
          flat
        >
          <template v-slot:heading>
            <v-tabs
              v-model="tabs"
              background-color="transparent"
              slider-color="white"
              grow
            >
              <v-tab class="mr-3">
                <v-icon class="me-2">
                  mdi-spa-outline
                </v-icon>
                Details
              </v-tab>
              <v-tab class="mr-3">
                <v-icon class="me-2">
                  mdi-calendar-clock
                </v-icon>
                Schedules
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items
            v-model="tabs"
            class="transparent"
          >
            <v-tab-item
              key="1"
            >
              <v-card-text>
                <validation-observer
                  ref="observer"
                >
                  <v-container>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Class"
                          rules="required"
                        >
                          <v-select
                            v-model="editedItem.class"
                            outlined
                            :items="classList"
                            item-text="name"
                            item-value="_id"
                            :error-messages="errors"
                            label="Select Class"
                            single-line
                            return-object
                          />
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Trainer"
                          rules="required"
                        >
                          <v-select
                            v-model="editedItem.client"
                            outlined
                            :items="clientList"
                            item-text="name"
                            item-value="_id"
                            label="Select Client"
                            :error-messages="errors"
                            single-line
                            return-object
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-container>
                </validation-observer>
              </v-card-text>
              <!--              <v-card-actions>-->
              <!--                <v-spacer />-->
              <!--                <v-btn-->
              <!--                  color="grey darken-1"-->
              <!--                  text-->
              <!--                  @click="close"-->
              <!--                >-->
              <!--                  Cancel-->
              <!--                </v-btn>-->
              <!--                <v-btn-->
              <!--                  color="primary"-->
              <!--                  @click="onAddClass"-->
              <!--                >-->
              <!--                  <v-icon left>-->
              <!--                    mdi-plus-->
              <!--                  </v-icon>-->
              <!--                  Save-->
              <!--                </v-btn>-->
              <!--              </v-card-actions>-->
            </v-tab-item>
            <!--                  ALL SCHEDULE DETAILS-->
            <v-tab-item
              key="2"
            >
              <!--                Days Selection-->
              <v-row justify="space-around">
                <v-col
                  cols="12"
                  sm="10"
                  md="8"
                >
                  <v-sheet
                    class="py-4 px-1 justify-center"
                  >
                    <v-chip-group
                      show-arrows
                      active-class="primary--text"
                    >
                      <v-chip
                        v-for="day in days"
                        :key="day.id"
                        outlined
                        filter
                        @click="day.click"
                      >
                        <b class="text-h4">{{ day.name }}</b>
                      </v-chip>
                    </v-chip-group>
                  </v-sheet>
                </v-col>
              </v-row>
              <!--Days Selection End-->
              <div style="border:1px solid #e3e3e3;height:460px;overflow-y:scroll;border-radius:20px">
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <!--                    Schedules on the booking-->
                    <v-container class="py-4 py-lg-8">
                      <div>
                        <div class="text-uppercase font-weight-bold text-body-2 grey--text text--darken-3 mb-2">
                          Time Schedule : <b class="green--text text--darken-3">{{ editedItem.class.name }}</b>
                        </div>
                      </div>
                      <div class="mt-6">
                        <div
                          v-for="item in daySchedules"
                          :key="item.activeDay"
                          class="mt-4"
                        >
                          <v-card
                            style="border-radius:50px"
                            class="py-1 px-2 elevation-1"
                            color="surface"
                          >
                            <!--                                  Test row-->
                            <v-row
                              no-gutters
                              style="border:1px solid white;border-radius:10px"
                            >
                              <v-col
                                cols="12"
                                sm="6"
                                md="8"
                              >
                                <v-card
                                  class="pa-0"
                                  flat
                                >
                                  <span class="text-h5 mx-2 font-weight-bold">
                                    From
                                  </span>
                                  <span class="">
                                    <v-chip
                                      outlined
                                      class="ma-2 font-weight-bold px-2"
                                      color="green"
                                    >
                                      <v-icon left>
                                        mdi-timer-outline
                                      </v-icon>
                                      <b class="text-h4">{{ item.startTime }}</b>
                                    </v-chip>
                                  </span>
                                  <v-divider vertical />
                                  <span class="text-h5 mx-2 font-weight-bold">
                                    To
                                  </span>
                                  <span class="">
                                    <v-chip
                                      outlined
                                      class="ma-2 font-weight-bold px-2"
                                      color="red"
                                    >
                                      <v-icon left>
                                        mdi-timer
                                      </v-icon>
                                      <b class="text-h4">{{ item.endTime }}</b>
                                    </v-chip>
                                  </span>
                                </v-card>
                              </v-col>
                              <v-col
                                cols="6"
                                md="4"
                              >
                                <v-card
                                  class="pa-2"
                                  flat
                                >
                                  <v-btn
                                    rounded
                                    small
                                    class="primary"
                                    @click="bookSchedule(item)"
                                  >
                                    <v-icon
                                      small
                                      class="mr-2"
                                    >
                                      mdi-plus
                                    </v-icon>
                                    Book Schedule
                                  </v-btn>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card>
                        </div>
                      </div>
                    </v-container>
                  </v-col>
                  <v-divider vertical />
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <!--                    Schedules being Booked-->
                    <v-container class="py-4 py-lg-8">
                      <div>
                        <div class="text-uppercase font-weight-bold text-body-2 grey--text text--darken-3 mb-2">
                          Booked Schedules : <b class="green--text text--darken-3">{{ editedItem.client.firstName }}</b>
                        </div>
                      </div>
                      <div class="mt-6">
                        <div
                          v-for="item in editedItemSchedules"
                          :key="item.activeDay"
                          class="mt-4"
                        >
                          <v-card
                            style="border-radius:50px"
                            class="py-1 px-2 elevation-1"
                            color="surface"
                          >
                            <v-row
                              no-gutters
                              style="border:1px solid white;border-radius:10px"
                            >
                              <v-col
                                cols="12"
                                sm="6"
                                md="8"
                              >
                                <v-card
                                  class="pa-0"
                                  flat
                                >
                                  <span class="text-h5 mx-2 font-weight-bold">
                                    From
                                  </span>
                                  <span class="">
                                    <v-chip
                                      outlined
                                      class="ma-2 font-weight-bold px-2"
                                      color="green"
                                    >
                                      <v-icon left>
                                        mdi-timer-outline
                                      </v-icon>
                                      <b class="text-h4">{{ item.startTime }}</b>
                                    </v-chip>
                                  </span>
                                  <v-divider vertical />
                                  <span class="text-h5 mx-2 font-weight-bold">
                                    To
                                  </span>
                                  <span class="">
                                    <v-chip
                                      outlined
                                      class="ma-2 font-weight-bold px-2"
                                      color="red"
                                    >
                                      <v-icon left>
                                        mdi-timer
                                      </v-icon>
                                      <b class="text-h4">{{ item.endTime }}</b>
                                    </v-chip>
                                  </span>
                                </v-card>
                              </v-col>
                              <v-col
                                cols="6"
                                md="4"
                              >
                                <v-card
                                  class="pa-2"
                                  flat
                                >
                                  <v-btn
                                    small
                                    text
                                    class="red--text"
                                    @click="removeShed(item)"
                                  >
                                    <v-icon
                                      small
                                      class="mr-2"
                                    >
                                      mdi-delete
                                    </v-icon>
                                    Remove
                                  </v-btn>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card>
                        </div>
                      </div>
                    </v-container>
                  </v-col>
                </v-row>
              </div>
              <!--                            Card Actions-->
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="grey darken-1"
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :loading="loader"
                  color="primary"
                  @click="onSaveBooking"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Save Schedule
                </v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
        <!--              here-->
      </v-card>
    </v-dialog>
    <!--    form Dialog-->
    <!--   Reset Form Dialog-->
    <!--    Form Dialog End-->
    <!--    Delete Dialog Start-->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this client?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    Delete Dialog end-->

    <!--    All Dialogs End-->
    <!--    Base Material Start -->
    <div v-if="fetchup">
      <v-skeleton-loader
        type="table-heading, list-item-two-line, image, table-tfoot"
      />
    </div>
    <base-material-card
      v-else
      color="black"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">
          <v-row class="align-content-md-space-between">
            <v-col
              cols="12"
              md="9"
              sm="6"
            >
              <div class="text-h3 font-weight-light">
                <v-icon
                  left
                  class="primary--text"
                >
                  mdi-spa-outline
                </v-icon> Manage Bookings <i class="text-caption">Total: <b>{{ total }}</b></i>
              </div>
              <div class="text-subtitle-1 font-weight-light">
                Please manage all Bookings
              </div>
            </v-col>
            <v-col
              md="3"
              sm="6"
              style="text-align:right"
            >
              <v-btn
                color="primary"
                dark
                outlined
                class="mb-2 subheading font-weight-bold"
                @click="openDialog"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                Add New Booking
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>

      <v-data-table
        :headers="headers"
        :items="classes"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:item.bookingDate="{ item }">
          {{ moment(item.bookingDate).format('MMM Do YYYY, h:mm a') }}
          || <b class="green--text text--darken-2">{{ moment(item.bookingDate).fromNow() }}</b>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            text
            class="grey--text"
            @click="editItem(item)"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-pencil
            </v-icon>
            Edit
          </v-btn>
          <v-btn
            small
            text
            class="red--text"
            @click="deleteItem(item)"
          >
            <v-icon
              small
              class="mr-2"
            >
              mdi-delete
            </v-icon>
            Delete
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-btn
            text
            small
            color="primary--text"
            @click="fetchBookings"
          >
            <v-icon left>
              mdi-refresh
            </v-icon>Reset
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
    <!--    Base Material End-->
  </v-container>
</template>
<script>
  /* eslint-disable */
  import VueTimepicker from 'vue2-timepicker'
  // CSS
  import 'vue2-timepicker/dist/VueTimepicker.css'

  import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })
  const axios = require('axios')
  export default {
    name: 'ManageBookings',
    data () {
      return {
        activeDay:'',
        tempTrainer:'',
        activeClass:'',
        loader:false,
        total:0,
        days: [
          {id:1,name:"Monday",click:()=>{ this.activeDay = 'Monday'}},
          {id:2,name:"Tuesday",click:()=>{this.activeDay = 'Tuesday'}},
          {id:3,name:"Wednesday",click:()=>{this.activeDay = 'Wednesday'}},
          {id:4,name:"Thursday",click:()=>{this.activeDay = 'Thursday'}},
          {id:5,name:"Friday",click:()=>{this.activeDay = 'Friday'}},
          {id:6,name:"Saturday",click:()=>{this.activeDay = 'Saturday'}},
          {id:7,name:"Sunday",click:()=>{this.activeDay = 'Sunday'}},
        ],
        selected: [2],
        schedules: [],
        tabs: 0,
        menu: false,
        switch1: false,
        dialog: false,
        fetchup: false,
        dialogDelete: false,
        err:[],
        headers: [
          {
            text: 'Class  Name',
            align: 'start',
            sortable: false,
            value: 'className',
          },
          { text: 'Client', value: 'clientName', sortable: false, align: 'center' },
          { text: 'Schedules', value: 'scheduleCount', sortable: false, align: 'center' },
          { text: 'Status', value: 'status', sortable: false, align: 'center' },
          { text: 'Book Date', value: 'bookingDate', sortable: false, align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
        ],
        classes: [],
        editedIndex: -1,
        editedItem: {
         class:{},
          client:{},
          schedules:[],
          status:'active',
          bookingDate: new Date()
        },
        defaultItem: {
          class:{},
          client:{},
          schedules:[],
          status:'active',
          bookingDate: new Date()
        },
      }
    },

    computed: {
      tabby(){
        return this.tabs;
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Add New Class ' : 'Edit Class '
      },

      clientList(){
        return this.$store.getters.getClientList;
      },
      classList(){
        return this.$store.getters.getClassList;
      },
      getActiveDay(){
        return this.activeDay;
      },
      daySchedules(){
        return this.schedules.filter((e)=>{
          return e.day === this.activeDay;
        });
      },
      editedItemSchedules(){
        return this.editedItem.schedules.filter((e)=>{
          return e.day === this.activeDay;
        });
      },

    },

    watch: {
      tabby(val){
        if(val > 0 && this.activeClass === ''){
          // alert('am tabby goood')
          this.fetchClassSchedules();
          // this.$store.commit('setSnack', { t: 'Please first add class details', c: 'red darken-3' })
          return false;
        }else{

        }
      },
      dialog (val) {
        val || this.close();
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted () {
      this.fetchBookings()
      this.$store.dispatch('fetchClientList')
      this.$store.dispatch('fetchClassList')
    },

    methods: {
      openDialog(){
        this.dialog = true
      },
      addShed(){
        this.schedules.push(
          {
            day:this.activeDay,
            startTime: '',
            endTime: '',
            class:this.activeClass,
            withAdd:false,
            trainer: this.tempTrainer
          }
        )
      },

      bookSchedule(payload){
        const index = this.daySchedules.indexOf(payload);
        this.daySchedules[index].booked = true;
        let shedExists = this.editedItem.schedules.includes(payload);

        if(!shedExists){
          this.editedItem.schedules.push(payload);
          this.$store.commit('setSnack', { t: 'Success! Schedule Booked', c: 'green darken-3' })
        }else{
          this.$store.commit('setSnack', { t: 'Schedule already exists', c: 'red darken-3' })
        }
      },

      removeShed(payload){
        const index = this.editedItem.schedules.indexOf(payload);
        this.editedItem.schedules.splice(index, 1);
      },

      fetchBookings () {
        this.fetchup = true
        const self = this
        axios.get(this.apiSet.bookings)
          .then(response => {
            let bookSet = [];
            const obj = response.data
            console.log({Booking:obj});
            for(let app of obj) {
              bookSet.push(
                {
                  fullSet: app,
                  schedules: app.schedules,
                  status: app.status,
                  bookingDate: app.bookingDate,
                  scheduleCount: app.schedules.length,
                  className: app.schedules[0].class.name,
                  trainerName: app.schedules[0].trainer.firstName,
                  clientName: app.client.firstName+' '+ app.client.lastName
                }
              )
            }
            self.classes = bookSet
            self.total = bookSet.length
            self.fetchup = false
          }).catch(
          e => {
            self.fetchup = false
            this.err.push(e)
          },
        )
      },
      fetchClassSchedules () {
        this.fetchup = true
        const self = this
        let scheduleID = this.editedItem.class? this.editedItem.class._id: ''
        axios.get(this.apiSet.classSchedules+'/'+scheduleID)
          .then(response => {
            const obj = response.data.schedules
            console.log({schedulesetters:response});
            self.schedules = obj
            self.fetchup = false
            // self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
          }).catch(
          e => {
            self.fetchup = false
            this.err.push(e)
          },
        )
      },

      onAddClass() {
        if (this.editedIndex > -1) {
          this.updateClass()
        } else {
          const self = this
          this.$refs.observer.validate().then((result) => {
            if (result) {
              this.loader = true
              const catData = this.editedItem

              axios.post(this.apiSet.classes, this.editedItem)
                .then(function (response) {
                  // self.close()
                  console.log({sharp: response.data.data })
                  let returnClass = response.data.data.class
                  self.tempTrainer = returnClass.trainer
                  self.activeClass = returnClass._id
                  self.fetchClass()
                  self.tab = 1;
                  self.loader = false
                  self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
                })
                .catch(error => {
                  self.loader = false
                  console.log(error)
                  self.close()
                })
            } else {
              self.$store.commit('setSnack', { t: 'Please correct form errors.', c: 'red darken-3' })
              return false
            }
          })
        }
      },

      onSaveBooking() {
        if (this.editedIndex > -1) {
          // this.updateSchedule()
        } else {
          const self = this
          // this.$refs.observer.validate().then((result) => {
          //   if (result) {
          this.loader = true
          const catData = this.editedItem

          axios.post(this.apiSet.bookings, catData)
            .then(function (response) {
              self.close()
              self.fetchBookings()
              self.loader = false
              self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
            })
            .catch(error => {
              self.loader = false
              console.log(error)
              self.close()

            })
          // } else {
          //   self.$store.commit('setSnack', { t: 'Please correct form errors.', c: 'red darken-3' })
          //   return false
          // }
          // })
        }
      },

      fetchSchedule (scheduleID) {
        this.fetchup = true
        const self = this
        axios.get(this.apiSet.classSchedules+'/'+scheduleID)
          .then(response => {
            const obj = response.data.schedules
            console.log({schedulesetters:response});
            self.schedules = obj
            self.total = obj.length
            self.fetchup = false
            // self.$store.commit('setSnack', { t: 'Success. Data was successfully added.', c: 'green darken-3' })
          }).catch(
          e => {
            self.fetchup = false
            this.err.push(e)
          },
        )
      },


      updateClass () {
        const self = this
        this.loader = true
        const catData = this.editedItem

        axios.put(this.apiSet.class+'/'+this.editedItem._id,
          {
            name: catData.name,
          })
          .then(function (response) {
            self.close()
            self.fetchClass()
            self.loader = false
            self.$store.commit('setSnack', { t: 'Success. Data was successfully updated.', c: 'green darken-3' })
          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
      },
      updateSchedule () {
        const self = this
        this.loader = true
        const catData = this.editedItem

        axios.put(this.apiSet.class+'/'+this.editedItem._id,
          {
            name: catData.name,
          })
          .then(function (response) {
            self.close()
            self.fetchClass()
            self.loader = false
            self.$store.commit('setSnack', { t: 'Success. Data was successfully updated.', c: 'green darken-3' })
          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
      },

      editItem (item) {
        this.editedIndex = this.classes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        setTimeout(() => {this.fetchSchedule(item._id)}, 2000)
      },

      deleteItem (item) {
        this.editedIndex = this.classes.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.classes.splice(this.editedIndex, 1)
        const self = this
        this.loader = true
        const delId = this.editedItem._id

        axios.delete(this.apiSet.classes+'/'+delId)
          .then(function (response) {
            self.loader = false
            self.$store.commit('setSnack', { t: 'Success. Data was successfully deleted.', c: 'green darken-3' })
            self.closeDelete()

          })
          .catch(error => {
            self.loader = false
            self.err.push(error)
          })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.classes[this.editedIndex], this.editedItem)
        } else {
          this.classes.push(this.editedItem)
        }
        this.close()
      },
    },
    components: {
      VueTimepicker
    },
  }
</script>

<style lang="scss">
/* Default override (not using "append-to-body") */
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: steelblue;
}

/* When using "append-to-body" */
.vue__time-picker-dropdown ul li:not([disabled]).active {
  background: steelblue;
}
</style>
